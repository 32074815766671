import React, { useEffect, useRef } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"

//logo de empresas clientes
import Culqi from "@components/sharedComponents/sliderCustomersLogos/logos/culqi.svg"
import Auna from "@components/sharedComponents/sliderCustomersLogos/logos/auna.svg"
import ProEmpresa from "@components/sharedComponents/sliderCustomersLogos/logos/proEmpresa.svg"
import Astara from "@components/sharedComponents/sliderCustomersLogos/logos/astara.svg"
import Savar from "@components/sharedComponents/sliderCustomersLogos/logos/savar.svg"
import Cobra from "@components/sharedComponents/sliderCustomersLogos/logos/cobra.svg"
import Civa from "@components/sharedComponents/sliderCustomersLogos/logos/civa.svg"
import Royal from "@components/sharedComponents/sliderCustomersLogos/logos/royal.svg"
import CasaRossello from "@components/sharedComponents/sliderCustomersLogos/logos/casaRossello.svg"
import Aviva from "@components/sharedComponents/sliderCustomersLogos/logos/aviva.svg"
import Enseña from "@components/sharedComponents/sliderCustomersLogos/logos/enseña.svg"
import Synlab from "@components/sharedComponents/sliderCustomersLogos/logos/synlab.svg"

const data = [
  {
  img: Culqi,
  metrics: "x8",
  text: "aumentó sus ventas"
},
{
  img: Auna,
  metrics: "5pp",
  text: "aumentó su conversión",
},
{
  img: ProEmpresa,
  metrics: "+20%",
  text: "mejoró su CSAT"
}, 
{
  img: Astara,
  metrics: "93%",
  text: "redujo esfuerzo en remarketing"
}, 
{
  img: Savar,
  metrics: "+30%",
  text: "redujo consultas repetitivas"
}, 
{
  img: Cobra,
  metrics: "+20%",
  text: "mejoró su contactabilidad por voz"
}, 
{
  img: Civa,
  metrics: "1min",
  text: "redujo su TMO"
}, 
{
  img: Royal,
  metrics: "14%",
  text: "aumento leads que agendan visitas"
},
{
  img: CasaRossello,
  metrics: "+65%",
  text: "aumento de recompra"
},
{
  img: Aviva,
  metrics: "+30%",
  text: "mejoró tiempo de agenda de citas"
},
{
  img: Synlab,
  metrics: "20seg",
  text: "disminuyeron respuestas por voz"
},
{
  img: Enseña,
  metrics: "+40%",
  text: "aumento de donantes"
},

]


const SliderCustomer = () => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  //funcion para slider de logos clientes
  var settings = {
    arrows: false,
    autoplay: true,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          initialSlide: 0,
        },
      },
    ],
  }
  return (
    <section className="container-slider-customer">
        <section className="container-slider-customer-logos" ref={fromRef}>
          <Slider ref={sliderRef} {...settings}>
            {data.map((elemento, i) => {
              return (
                <div key={elemento} className="container-slider-customer-logos-card">
                  <img className="container-slider-customer-logos-card-image" src={elemento.img} alt="" />
                  <p  className="container-slider-customer-logos-card-metrics">{elemento.metrics}</p>
                  <p  className="container-slider-customer-logos-card-text">{elemento.text}</p>
                </div>
              )
            })}
          </Slider>
      </section>
    </section>
  )
}
export default SliderCustomer
